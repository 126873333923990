<template>
    <div class="container-box" v-loading="loading" element-loading-text="加载中...">
        <div class="box-left">
            <div class="search-control">
                <search-control placeholder="请输入专业代码或名称" :value.sync="keyword" @search-click="onSearchClick"></search-control>
            </div>
            <div class="dark-border school-filter">
                <major-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" @filter="onFilterClick"></major-filter>
            </div>
            <div v-for="(group1, index1) in groups1()" :key="index1">
                <header-entry class="header-entry">{{ group1.slice(2) }}</header-entry>
                <div class="bottom-line"></div>
                <header-container v-for="(group2, index2) in groups2(group1)" :key="index2" :title="group2.slice(4)" class="school-list">
                    <div>
                        <div v-for="(group3, index3) in groups3(group2)" :key="index3">
                            <major-item :title="group3.name" :code="group3.code" :type="group3.type" :img="schoolImg(group3.code)" @click="onItemClick(group3.code)"></major-item>
                            <div v-if="index3 < groups3(group2).length - 1" class="bottom-line"></div>
                        </div>
                    </div>
                </header-container>
            </div>
            <div class="pagination-box">
                <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
            </div>
        </div>
        <div class="box-right">
            <school-hot></school-hot>
        </div>
    </div>
</template>

<script>
import MajorFilter from "@/components/PC/MajorFilter";
import MajorItem from "@/components/PC/MajorItem";
import HeaderEntry from "@/components/PC/HeaderEntry";
import HeaderContainer from "@/components/PC/HeaderContainer";
import SchoolHot from "@/components/PC/SchoolHot";
import SearchControl from "@/components/PC/SearchControl";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        MajorFilter,
        MajorItem,
        HeaderEntry,
        HeaderContainer,
        SchoolHot,
        SearchControl,
    },
    data() {
        return {
            loading: true,
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            keyword: '',
            filter1: [],
            filter2: '',
            filter3: [],
            majorGroups: [],
        };
    },
    computed: {
        schoolImg() {
            return function (code) {
                return `${Config.school.logo}?SchoolCode=${code}`;
            }
        },
        groups1() {
            return function () {
                var groups = [];
                for (var i = 0; i < this.majorGroups.length; i++) {
                    if (groups.indexOf(this.majorGroups[i].MajorBelongcategory) < 0) {
                        groups.push(this.majorGroups[i].MajorBelongcategory);
                    }
                }
                return groups;
            }
        },
        groups2() {
            return function (belongcategory) {
                var groups = [];
                for (var i = 0; i < this.majorGroups.length; i++) {
                    if (this.majorGroups[i].MajorBelongcategory == belongcategory && groups.indexOf(this.majorGroups[i].MajorBelongFirstDiscipline) < 0) {
                        groups.push(this.majorGroups[i].MajorBelongFirstDiscipline);
                    }
                }
                return groups;
            }
        },
        groups3() {
            return function (belongFirstDiscipline) {
                var groups = [];
                for (var i = 0; i < this.majorGroups.length; i++) {
                    if (this.majorGroups[i].MajorBelongFirstDiscipline == belongFirstDiscipline) {
                        groups.push({
                            code: this.majorGroups[i].MajorCode,
                            name: this.majorGroups[i].MajorName,
                            type: this.majorGroups[i].MajorDegreeType,
                        });
                    }
                }
                return groups;
            }
        }
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
            this.filter2 = this.$route.query.majorBelongcategory ? this.$route.query.majorBelongcategory : '';
            this.filter3 = this.$route.query.majorBelongFirstDiscipline ? [this.$route.query.majorBelongFirstDiscipline] : [];
        },
        //获取大学列表
        getMajorInfos() {
            this.loading = true;
            var model = {
                SearchKeyword: this.keyword,
                MajorDegreeTypes: this.filter1,
                MajorBelongcategory: this.filter2,
                MajorBelongFirstDisciplines: this.filter3,
                PageIndex: this.pageIndex,
                PageSize: this.pageSize,
            };
            this.$axios
                .post(Config.major.groupList, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.totalCount = data.TotalCount;
                    this.majorGroups = data.MajorGroups;
                    this.loading = false;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //点击专业项
        onItemClick(majorCode) {
            this.$router.push({
                name: "Major1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    majorCode: majorCode
                }
            });
        },
        //点击搜索
        onSearchClick() {
            this.pageIndex = 1;
            this.filter1 = [];
            this.filter2 = '';
            this.filter3 = [];
            this.getMajorInfos();
        },
        //点击过滤
        onFilterClick() {
            this.pageIndex = 1;
            this.keyword = '';
            this.getMajorInfos();
        },
        //点击分页
        onCurrentChange() {
            this.getMajorInfos();
        }
    },
    mounted() {
        this.getRoutParams();
        this.getMajorInfos();
    }
};
</script>
<style scoped>
.container-box {
    display: flex;
    flex-direction: row;
}

.box-left {
    flex: 1;
}

.box-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.header-entry {
    margin-top: 50px;
}

.search-control {
    width: 400px;
    margin: 10px 0;
}

.school-filter {
    padding: 20px;
}

.school-list {
    margin-top: 20px;
}

.dark-border {
    border: 1px solid #e6e6e6;
}

.bottom-line {
    border-top: 1px solid #e6e6e6;
    margin: 5px 0px;
}

.pagination-box {
    text-align: center;
    margin: 10px;
}
</style>