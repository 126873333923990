<template>
    <div class="major-tag" :style="{ 'background-image': `url(${require('@/assets/images/mt/major-tag.png')})` }">
        <span class="major-txt1">专业代码</span>
        <span class="major-txt2">{{ code }}</span>
    </div>
</template>
  
<script>
export default {
    name: "MajorTag",
    components: {

    },
    props: {
        code: {
            type: String,
            default: '025100',
        },
    },
    data() {
        return {};
    },
    methods: {

    },
};
</script>
  
<style scoped>
.major-tag {
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2px;
    font-size: 14px;
    background-size: 100% 100%;
}

.major-txt1 {
    color: #FF6602;
    font-size: 13px;
}

.major-txt2 {
    margin-left: 12px;
    color: white;
    font-size: 13px;
}
</style>
  