<template>
  <div class="box" @click="onClick">
    <div class="box2">
      <div class="line1">
        <span class="major-name">
          {{ name }}
        </span>
        <major-tag :code="code" class="major-tag"></major-tag>
      </div>
      <div class="line2">
        <span class="major-type">专业类别：</span>
        <span class="major-type">{{ level1 }}</span>
        <span class="verticalbar"></span>
        <span class="major-type">{{ level2 }}</span>
        <span class="verticalbar"></span>
        <span class="major-type">{{ level3 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import MajorTag from "@/components/MT/MajorTag";
export default {
  name: "MajorItem",
  components: {
    MajorTag
  },
  props: {
    name: {
      type: String,
      default: '金融',
    },
    code: {
      type: String,
      default: '025100',
    },
    level1: {
      type: String,
      default: '专业型硕士',
    },
    level2: {
      type: String,
      default: '经济学',
    },
    level3: {
      type: String,
      default: '金融',
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 0 10px;
}

.box1 {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .school-img {
    width: 50px;
    height: 50px;
  }
}

.box2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .major-name {
    cursor: pointer;
    color: rgba(0, 0, 0, .88);
    font-size: 16px;
    font-weight: 500;
    width: calc(100% - 140px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.box:hover {
  color: #FF6602;
}

.line1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .major-tag {
    margin-right: 5px;
  }
}

.line2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  .major-type {
    font-size: 13px;
    color: rgba(0, 0, 0, .4);
  }

  .verticalbar {
    border-bottom: 1px solid gray;
    content: "";
    display: inline-block;
    width: 5px;
    height: 1px;
    margin: 0 5px;
    top: 2px;
  }
}
</style>
