<template>
    <div>
        <van-sticky>
            <title-bar2 title="查专业" />
            <title-search :value.sync="keyword" placeholder="请输入专业代码或名称" @search="onSearch" />
            <major-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" @filter="onFilter"></major-filter>
        </van-sticky>
        <div class="major-total">专业（{{ total }}个）</div>
        <van-list class="major-list" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <major-item class="major-item" v-for="(major, index) in majors" :key="index" :name="major.MajorName" :code="major.MajorCode" :level1="major.MajorDegreeType" :level2="major.MajorBelongcategory" :level3="major.MajorBelongFirstDiscipline" @click="onItemClick(major)"></major-item>
        </van-list>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import TitleSearch from "@/components/MT/TitleSearch";
import MajorFilter from "@/components/MT/MajorFilter";
import MajorItem from "@/components/MT/MajorItem";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
        TitleSearch,
        MajorFilter,
        MajorItem,
    },
    data() {
        return {
            keyword: null,
            index: 0,
            total: 0,
            loading: false,
            finished: false,
            filter1: [],
            filter2: '',
            filter3: [],
            majors: [],
        };
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //点击搜索
        onSearch() {
            this.filter1 = [];
            this.filter2 = '';
            this.filter3 = [];
            this.onReload();
        },
        //点击过滤
        onFilter() {
            this.keyword = '';
            this.onReload();
        },
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.majors = [];
            this.onLoad();
        },
        //加载当前页数据
        onLoad() {
            var model = {
                SearchKeyword: this.keyword,
                MajorDegreeTypes: this.filter1,
                MajorBelongcategory: this.filter2,
                MajorBelongFirstDisciplines: this.filter3,
                PageIndex: ++this.index,
                PageSize: 20,
            };
            this.$axios
                .post(Config.major.groupList, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.majors.push(...data.MajorGroups);
                    this.loading = false;
                    this.total = data.TotalCount;
                    if (this.index >= data.PageCount) {
                        this.finished = true;
                    }
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //点击专业项
        onItemClick(major) {
            this.$router.push({
                name: "Major1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    majorCode: major.MajorCode
                }
            });
        },
    },
    mounted() {
        this.getRoutParams();
        this.onLoad();
    },
};
</script>

<style scoped>
.major-total {
    margin-left: 10px;
    color: gray;
    font-size: 14px;
}

.major-list {
    margin-top: 10px;
}

.major-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}
</style>