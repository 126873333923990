<template>
  <div class="box" @click="onClick">
    <div class="box2">
      <div class="line1">
        <span class="title"> {{ title }} </span>
      </div>
      <div class="line2">
        <div>
          <span class="text">专业代码：{{ code }}</span>
          <span class="verticalbar"></span>
          <span class="text">学位类型：{{ type }}</span>
        </div>
      </div>
    </div>
    <div class="box3">
      <el-button type="primary" size="small" @click="onClick">开设院校</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MajorItem",
  components: {

  },
  props: {
    title: {
      type: String,
      default: '北京大学',
    },
    code: {
      type: String,
      default: '001001',
    },
    type: {
      type: String,
      default: '学术型硕士',
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}

.box2:hover {
  color: #FF6602;
}

.box3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.line1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}

.line2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.text {
  font-size: 14px;
  color: #555555;
}

.verticalbar {
  border-left: 1px solid #c5c5c5;
  content: "";
  display: inline-block;
  width: 1px;
  height: 10px;
  margin: 0 10px;
  top: 2px;
}
</style>
