<template>
    <div class="title-container">
        <div class="title-border">{{ title }}</div>
        <div class="content-border">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderContainer",
    components: {
    },
    props: {
        title: {
            type: String,
            default: '标题',
        },
    },
    data() {
        return {

        };
    },
    methods: {

    },
};
</script>

<style lang="less" scoped>

.title-border {
    background-color: white;
    border: 1px solid #e6e6e6;
    border-bottom: none;
    font-weight: 600;
    font-size: 16px;
    color: #f60;
    padding: 8px 15px;
    display: inline-block;
    position: relative;
    top: 1px;
}

.content-border {
    border: 1px solid #e6e6e6;
    padding: 15px;
}
</style>
