<template>
  <van-dropdown-menu>
    <van-dropdown-item ref="dropdown1" :title="title1">
      <div class="dropdown-fill">
        <radio-item label="全部" icon :checked="value1.length == 0" @click="onClear1"></radio-item>
        <check-item-group v-model="value1">
          <check-item label="专业型硕士" name="专业型硕士" icon @click="onClick1"></check-item>
          <check-item label="学术型硕士" name="学术型硕士" icon @click="onClick1"></check-item>
        </check-item-group>
      </div>
    </van-dropdown-item>
    <van-dropdown-item ref="dropdown2" :title="title2">
      <div class="dropdown-fill sidebar-box">
        <div class="sidebar-left">
          <radio-item-group v-model="value2">
            <radio-item label="全部" name="" icon @click="onClear2"></radio-item>
            <radio-item v-for="item in list2" :key="item" :label="item" :name="item" icon @click="onClick2"></radio-item>
          </radio-item-group>
        </div>
        <div class="sidebar-right">
          <radio-item label="全部" icon :checked="value3.length == 0" @click="onClear3"></radio-item>
          <check-item-group v-model="value3">
            <check-item v-for="item in list3" :key="item" :label="item" :name="item" icon @click="onClick3"></check-item>
          </check-item-group>
        </div>
      </div>
    </van-dropdown-item>
  </van-dropdown-menu>
</template>

<script>
import RadioItem from "@/components/MT/RadioItem";
import RadioItemGroup from "@/components/MT/RadioItemGroup";
import CheckItem from "@/components/MT/CheckItem";
import CheckItemGroup from "@/components/MT/CheckItemGroup";
import Config from "@/api/service";
export default {
  name: "MajorFilter",
  components: {
    RadioItem,
    RadioItemGroup,
    CheckItem,
    CheckItemGroup,
  },
  props: {
    filter1: {
      type: Array,
      default: [],
    },
    filter2: {
      type: String,
      default: '',
    },
    filter3: {
      type: Array,
      default: [],
    },
  },
  computed: {
    title1() {
      if (this.value1.length > 0) {
        return this.value1.join(',');
      } else {
        return "学位类别";
      }
    },
    title2() {
      if (this.value3.length > 0) {
        return this.value3.join(',');
      } else if (this.value2) {
        return this.value2;
      } else {
        return "专业类别";
      }
    },
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
  },
  data() {
    return {
      list2: [],
      list3: [],
    };
  },
  methods: {
    getList2() {
      setTimeout(() => {
        this.list2 = [];
        this.list3 = [];
        var model = {
          MajorDegreeTypes: this.value1,
        };
        this.$axios
          .post(Config.major.filter.belongCategoryList, model)
          .then((res) => {
            this.list2 = res.data.Data.MajorBelongcategorys;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    getList3() {
      setTimeout(() => {
        this.list3 = [];
        var model = {
          MajorDegreeTypes: this.value1,
          MajorBelongcategory: this.value2,
        };
        this.$axios
          .post(Config.major.filter.firstDisciplineList, model)
          .then((res) => {
            this.list3 = res.data.Data.MajorBelongFirstDisciplines;
          })
          .catch((error) => {
            this.$dialog({ title: "错误", message: error.message });
          });
      }, 0)
    },
    onClear1() {
      this.value1 = [];
      this.onClick1();
    },
    onClear2() {
      this.value2 = '';
      this.onClick2();
    },
    onClear3() {
      this.value3 = [];
      this.onClick3();
    },
    onClick1() {
      this.value2 = '';
      this.value3 = [];
      this.$emit("filter");
      this.getList2();
    },
    onClick2() {
      this.value3 = [];
      this.$emit("filter");
      this.getList3();
    },
    onClick3() {
      this.$emit("filter");
    },
    onToggle(num) {
      if (num == 1) {
        this.$refs.dropdown1.toggle(false);
      } else if (num == 2) {
        this.$refs.dropdown2.toggle(false);
      }
    }
  },
  mounted() {
    this.getList2();
  }
};
</script>

<style scoped>
.dropdown-fill {
  flex: 1;
}

.sidebar-box {
  display: flex;
}

.sidebar-left {
  width: 40%;
  background-color: #F6F6F6;
  overflow-y: auto;
}

.sidebar-right {
  width: 60%;
  background-color: white;
  overflow-y: auto;
}

::v-deep .van-dropdown-item__content {
  display: flex;
}

::v-deep .van-dropdown-menu__bar {
  background-color: #F6F6F6;
  box-shadow: unset;
}
</style>
