<template>
  <div>
    <div class="box flex">
      <div class="text1">学位类别</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value1">
        <radio-item label="全部" :checked="value1.length == 0" @click="onClear1"></radio-item>
        <check-item label="专业型硕士" name="专业型硕士" @click="onClick1"></check-item>
        <check-item label="学术型硕士" name="学术型硕士" @click="onClick1"></check-item>
      </check-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex">
      <div class="text1">门 类</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <radio-item-group class="flex fill" v-model="value2">
        <div class="fill">
          <radio-item label="全部" name="" @click="onClear2"></radio-item>
          <radio-item v-for="(item, index) in list2" :key="index" :label="item" :name="item" @click="onClick2"></radio-item>
        </div>
      </radio-item-group>
    </div>
    <div class="margin-column"></div>
    <div class="box flex" v-if="list3.length > 0">
      <div class="text1">学科类别</div>
      <img src="@/assets/images/pc/right.png" class="img">
      <check-item-group class="flex fill" v-model="value3">
        <radio-item label="全部" :checked="value3.length == 0" @click="onClear3"></radio-item>
        <div class="fill">
          <check-item v-for="(item, index) in list3" :key="index" :label="item" :name="item" @click="onClick3"></check-item>
        </div>
      </check-item-group>
    </div>
  </div>
</template>

<script>
import RadioItem from "@/components/PC/RadioItem";
import RadioItemGroup from "@/components/PC/RadioItemGroup";
import CheckItem from "@/components/PC/CheckItem";
import CheckItemGroup from "@/components/PC/CheckItemGroup";
import Config from "@/api/service";
export default {
  name: "MajorFilter",
  components: {
    RadioItem,
    RadioItemGroup,
    CheckItem,
    CheckItemGroup,
  },
  props: {
    filter1: {
      type: Array,
      default: [],
    },
    filter2: {
      type: String,
      default: '',
    },
    filter3: {
      type: Array,
      default: [],
    },
  },
  computed: {
    value1: {
      get: function () {
        return this.filter1;
      },
      set: function (val) {
        this.$emit('update:filter1', val);
      }
    },
    value2: {
      get: function () {
        return this.filter2;
      },
      set: function (val) {
        this.$emit('update:filter2', val);
        this.getList2();
      }
    },
    value3: {
      get: function () {
        return this.filter3;
      },
      set: function (val) {
        this.$emit('update:filter3', val);
      }
    },
  },
  data() {
    return {
      list2: [],
      list3: [],
    };
  },
  methods: {
    getList2() {
      setTimeout(() => {
        this.list2 = [];
        this.list3 = [];
        var model = {
          MajorDegreeTypes: this.value1,
        };
        this.$axios
          .post(Config.major.filter.belongCategoryList, model)
          .then((res) => {
            this.list2 = res.data.Data.MajorBelongcategorys;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    getList3() {
      setTimeout(() => {
        this.list3 = [];
        var model = {
          MajorDegreeTypes: this.value1,
          MajorBelongcategory: this.value2,
        };
        this.$axios
          .post(Config.major.filter.firstDisciplineList, model)
          .then((res) => {
            this.list3 = res.data.Data.MajorBelongFirstDisciplines;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    onClear1() {
      this.value1 = [];
      this.onClick1();
    },
    onClear2() {
      this.value2 = '';
      this.onClick2();
    },
    onClear3() {
      this.value3 = [];
      this.onClick3();
    },
    onClick1() {
      this.value2 = '';
      this.value3 = [];
      this.getList2();
      this.$emit('filter');
    },
    onClick2() {
      this.value3 = [];
      this.getList3();
      this.$emit('filter');
    },
    onClick3() {
      this.$emit('filter');
    },
  },
  mounted() {
    this.getList2();
    if (this.filter3) {
      this.getList3();
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  font-size: 14px;
}

.img {
  width: 15px;
  height: 15px;
  padding: 10px 0;
  margin-top: 2px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.fill {
  flex: 1;
}

.text1 {
  padding: 10px;
  color: black;
  word-spacing: 24px;
}

.text2 {
  width: 30px;
  padding: 10px;
  color: gray;
}

.margin-column {
  margin: 20px 0;
}
</style>
